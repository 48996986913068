import { render, staticRenderFns } from "./organization.vue?vue&type=template&id=27709be8&scoped=true&"
import script from "./organization.vue?vue&type=script&lang=js&"
export * from "./organization.vue?vue&type=script&lang=js&"
import style0 from "./organization.vue?vue&type=style&index=0&id=27709be8&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27709be8",
  null
  
)

export default component.exports